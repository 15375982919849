import React, {useEffect, useState} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import moment from "moment-timezone";
import {FiSettings} from "react-icons/fi";
import AnalysisParametersForm from "./AnalysisParametersForm";


function getViewDescription(view){
    switch (view) {
        case "productionByPlant":     return "This figure shows the monthly production by each production plant (MWh)"
        case "totalCostByPlant":  return "This figure shows the average price per MWh produced, including the market price and all other payment components received by each plant."
        case "avgMarketPrice":        return "This figure shows the average market price captured by each plant. Different plants might have different captured prices depending on when they produce."
        case "averageRegulatedIncome":      return "This figure shows the average regulated income by plant."
        case "averageCostPerMWh":       return "This figure shows the cost by plant, taking into account investment costs and O&M costs."

        case "investorSummary": return ""
        case "balanceSheet":     return ""
       default:            return null;
    }
}

function InvestmentFilters({defaultView,
                               simulation,
                               simulationList,
                               updateView,
                               updateCompareSimulations,
                               updateCompareDataSeries,
                               simulationsToCompare,
                               dataSeriesToCompare,
                               simulationParametersList,
                               updateSelectedSimulationInitialValues,
                               simulationInitialValues,
                               updateAggregation,
                               userId
                           }) {

    const startSim = Date.parse(simulation["configuration"]["startEndSimulation"]["start"]);
    const endSim = Date.parse(simulation["configuration"]["startEndSimulation"]["end"]);

    const rowStyle = {
        marginBottom: "20px"
    };

    const [view, setView] = useState("marketPrice");
    const [simulationMinPeriod, setSimulationMinPeriod] = useState(moment(startSim).tz("GMT").toDate());
    const [simulationMaxPeriod, setSimulationMaxPeriod] = useState(moment(endSim).tz("GMT").toDate());
    const [openSettingsByParameters, setOpenSettingsByParameters] = useState(false);

    useEffect(()=>{
        setSimulationMaxPeriod(endSim);
        setSimulationMinPeriod(startSim);
    },[simulationsToCompare, startSim, endSim, simulationMaxPeriod, simulationMinPeriod,
            dataSeriesToCompare]);

    const updateViewExplanation = (view) =>{
        setView(view);
    };

    // const groupByOptions = ["Month", "Year", "All"];
    // const groupByOptionsMapping = {
    //     Month: "Month",
    //     Year: "Year",
    //     All: "Simulation Period"
    // }

    const viewDefinition = view && getViewDescription(view)?
        <Row style={rowStyle}>
            <Col sm={12}>
                <Card>
                    <Card.Body>
                        <Card.Text>
                            {getViewDescription(view)}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>: null;

    let regulatorViews = null;
    let investorViews = null;
    if(simulation.configuration.projectType === 'Renewables'){
        regulatorViews = <optgroup label="Regulator">
                                        <option value="summary">Regulator's Summary</option>
                                        <option value="productionByPlant">Production By Plant</option>
                                        <option value="totalCostByPlant">Average Price</option>
                                        <option value="avgMarketPrice">Captured Market Price</option>
                                        <option value="averageRegulatedIncome">Average Regulated Income</option>
                                    </optgroup>
        investorViews = <optgroup label="Investor">
                            <option value="investorSummary">Investor's Summary</option>
                            <option value="cashFlows">Cash Flows</option>
                            <option value="balanceSheet">Investor's Balance Sheet</option>
                            <option value="sensitivityAnalysis">Sensitivity Analysis</option>
                        </optgroup>
    }else if(['Hydrogen'].includes(simulation.configuration.projectType)){
        regulatorViews = <optgroup label="Regulator">
            <option value="hydrogenSummary">Regulator's Summary</option>
            <option value="electricityProduction">Electricity Production</option>
            <option value="hydrogenProduction">Hydrogen Production</option>
            <option value="production_by_destination_month">Production by Use</option>
            <option value="production_by_destination_%_month">Production by Use (%)</option>
            <option value="price_electricity_month">Price Electricity</option>
        </optgroup>
        investorViews = <optgroup label="Investor">
            <option value="investorSummaryHydro">Investor's Summary</option>
                            <option value="cashFlows">Cash Flows</option>
                            <option value="balanceSheet">Investor's Balance Sheet</option>
                            <option value="sensitivityAnalysis">Sensitivity Analysis</option>
                        </optgroup>
    }
    else if(['Hydrogrid'].includes(simulation.configuration.projectType)){
        regulatorViews = <optgroup label="Regulator">
            <option value="hydrogenSummary">Regulator's Summary</option>
            <option value="electricityProduction">Electricity Production</option>
            <option value="hydrogenProduction">Hydrogen Production</option>
            <option value="captureGridPrice">Captured Grid Price</option>
            <option value="productionByDestination">Production by Use</option>
            <option value="productionByUsePercentage">Production by Use (%)</option>
        </optgroup>
        investorViews = <optgroup label="Investor">
            <option value="investorSummaryHydro">Investor's Summary</option>
            <option value="cashFlows">Cash Flows</option>
            <option value="balanceSheet">Investor's Balance Sheet</option>
            <option value="sensitivityAnalysis">Sensitivity Analysis</option>
        </optgroup>
    }


    return (
        <div>
            <Row style={rowStyle}>
                <Col sm={12}>
                    <span className="filter-title">Valuation parameters</span>
                </Col>
                <Col sm={simulationParametersList.length === 0 ? 12 : 10}>
                    <Form.Control as="select" value={simulationInitialValues} aria-label="Initial values" size="sm" onChange={event=>{
                        updateSelectedSimulationInitialValues(event.target.value);
                    }}>
                        {
                            simulationParametersList.length === 0 ?
                                (<option key={"empty"} value={"--"}>--Please Add Parameters Valuation--</option>)
                                :
                            simulationParametersList.map(s => {
                                return <option key={s.id} value={s.id}>{s.name}</option>
                            })
                        }
                    </Form.Control>
                </Col>
                {simulationParametersList.length > 0 &&
                    <Col sm={2}>
                        <button type={"button"} className="btn btn-sm btn-outline-primary float-right"
                                onClick={()=>setOpenSettingsByParameters(true)}>
                            <FiSettings/>
                        </button>
                    </Col>
                }
            </Row>
            <Row style={rowStyle}>
                <Col sm={12}>
                    <span className="filter-title">Views</span>
                </Col>
                <Col sm={12}>
                    <Form.Control as="select" value={defaultView} aria-label="Period" size="sm" onChange={event=>{
                        updateView(event.target.value);
                        updateViewExplanation(event.target.value);
                    }}>
                        {regulatorViews}
                        {investorViews}
                    </Form.Control>
                </Col>
            </Row>
            {viewDefinition}
            {openSettingsByParameters &&
                <Row>
                    <AnalysisParametersForm simulation={simulation}
                                            showAddParameters={openSettingsByParameters}
                                            setShowAddParameters={setOpenSettingsByParameters}
                                            parameterId={simulationInitialValues}
                                            />
                </Row>
            }
        </div>
    );
}

export default InvestmentFilters;
