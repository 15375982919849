import React, {useEffect, useMemo, useState} from "react";
import {Alert, Button, Col, Form, InputGroup, Modal, Row, Table} from "react-bootstrap";
import {buildDateFromStr, ErrorFallback, sendNotification} from "../commons";
import {ErrorBoundary} from "react-error-boundary";
import HorizontalLine from "../common/HorizontalLine";
import {trackPromise} from "react-promise-tracker";
import {
    BiHistory,
    BiWater
} from "react-icons/bi";
import {
    BsTable
} from "react-icons/bs";
import {
    FaGasPump,
    FaRecycle
} from "react-icons/fa";
import {
    GiElectric,
    GiFactory
} from "react-icons/gi";
import {
    GoCalendar
} from "react-icons/go";
import {
    GrServerCluster,
    GrTechnology
} from "react-icons/gr";
import {
    SiMarketo
} from "react-icons/si";
import {useNavigate} from "react-router-dom";
import SimulationProgressMessages from "../simulations/SimulationProgressMessages";
import SimulationWarnings from "../simulations/SimulationWarnings";
import InvestmentPlants from "./InvestmentPlants";
import CreatableSelect from "react-select/creatable";

function invalidUnitDates(dateFrom, dateTo) {
    if(dateFrom !== '' && dateTo !== '') {
        let fromDate = buildDateFromStr(dateFrom);
        let toDate = buildDateFromStr(dateTo);
        return fromDate > toDate;
    }
    return false;
}

function getDefaultByType(type, dataSeries) {
    if(dataSeries.length > 0) {
        let dataSeriesType = dataSeries.filter(d => d.type === type)[0];
        if(dataSeriesType) {
            let defaultByType = dataSeriesType.files.filter(f => f.is_default);
            return defaultByType.length === 1 ? defaultByType[0].id : (dataSeriesType.files.length > 0 ? dataSeriesType.files[0].id : "");
        }
        return "";
    }
    return "";
}

function buildUnits(units) {
    let counter = 1;
    let results = [];
    units.forEach(i => {
        if(i["units"].length > 0){
            i["units"].forEach(u =>{
                results.push({
                    key: counter,
                    id: u["id"],
                    code: u["code"],
                    company: i["company_name"],
                    technology: i["technology"],
                    dateOn: u["start_date_fmt"],
                    dateOff: u["end_date_fmt"],
                    updated: false
                });
                counter++;
            })
        }
    });
    return results;
}

function buildFirmsData(data) {
    return data.reduce((a, v) => ({ ...a, [v["code"]]: {
            price: 0,
            quantity: 0,
            strategy: v["strategy"] === 1
        }}), {});
}

/**
 * @return {null}
 */
function InvestmentConfigForm({investment, dataSeries,
                                  marketStructureList,
                                  userId,
                                  finishSimulation,
                                  updateInvestment,
                                  updateInvestmentList,
                                  cancelSimulationChanges,
                                  loadInvestment,
                                  deleteSimulationModal,
                                  username,
                                  readOnly=false}) {

    let navigate = useNavigate();

    let defaultGasId = getDefaultByType("fuel", dataSeries);
    let defaultRenewableId = getDefaultByType("renewable", dataSeries);
    let defaultDemandId = getDefaultByType("demand", dataSeries);
    let defaultInterconnectionId = getDefaultByType("interconnection", dataSeries);
    let defaultMaintenanceId = getDefaultByType("maintenance", dataSeries);
    let defaultHydroId = getDefaultByType("hydro", dataSeries);
    let defaultMarketStructureId = marketStructureList && marketStructureList.length > 0 ? marketStructureList[0]["value"] : "";

    const defaultSimulationConfig = useMemo(() => ({
        id: investment.id,
        name: "",
        tag: "",
        companyCode: "",
        simulationType: "energeia-investment",
        projectType: "Renewables",
        timePeriod: {
            yearFrom: 2019,
            yearTo: 2050
        },
        marketStructureId: defaultMarketStructureId,
        demand:{
            id: defaultDemandId,
            variation: 0,
            inelasticPercentage: 0,
            peakiness: 0,
            maxPrice: 120,
            type: "elastic",
            growth: 1
        },
        hydrogen:{
            electrolysis: 15,
            electrolysisPower: 60,
            oCost: 3,
            h2oConsumption: 10,
            h2o_cost: 0.01,
            capacity: 0,
            feedInPremium: 0
        },
        firms: {},
        units: [],
        renewable: {
            solar: defaultRenewableId,
            solarTh: defaultRenewableId,
            wind: defaultRenewableId,
            cog: defaultRenewableId,
            others: defaultRenewableId,
            windFixPrice: 0,
            cogFixPrice: 0,
            othersFixPrice: 0,
            solarFixPrice: 0,
            solarThFixPrice: 0
        },
        hydro: {
            peak: "monthly",
            dispatch_id: defaultHydroId,
            flow_id: defaultHydroId,
            dispatchFixPrice: 0,
            flowFixPrice: 0,
            maxFlow: 5000
        },
        fuel:{
            coalId: defaultGasId,
            gasId: defaultGasId,
            taxGenerationId: defaultGasId,
            co2Id: defaultGasId,
            hydrogenId: defaultGasId,
            coalFixPrice: 0,
            gasFixPrice: 0,
            co2FixPrice: 0,
            hydrogenFixPrice: 0,
            taxGenerationFixPrice: 0,
        },
        interconnectionFixPrice: 0,
        interconnectionId: defaultInterconnectionId,
        maintenanceId: defaultMaintenanceId,
        priceId: undefined,
        technologyGenerationId: undefined,
        strategy: false,
        competitive: false,
        storage: false,
        resConnected: false,
        h2Connected: false,
        temporalCorrelation: "none",
        plants: []
    }), [investment, defaultGasId, defaultRenewableId, defaultDemandId, defaultInterconnectionId, defaultMaintenanceId, defaultHydroId, defaultMarketStructureId]);


    const [investmentConfig, setInvestmentConfig] = useState(defaultSimulationConfig);
    const [saveAsName, setSaveAsName] = useState("");
    const [firmsModalShow, setFirmsModalShow] = useState(false);
    const [showLogs, setShowLogs] = useState(false);
    const [unitsModalShow, setUnitsModalShow] = useState(false);
    const [showSaveAs, setShowSaveAs] = useState(false);
    const [showWarnings, setShowWarnings] = useState(false);
    const [validateForm, setValidateForm] = useState(false);
    const [status, setStatus] = useState(investment.status);
    const marketStructureOptions = marketStructureList ? marketStructureList.map(s=><option key={s.value} value={s.value}>{s.label}</option>) : null;
    const demandOptions = dataSeries ? dataSeries.filter(d=>d.type==="demand").map(s=>s.files.map(f=><option key={f.id} value={f.id}>{f.name} {f.is_default ? '(Default)' : ''}</option>)) : null;
    const hydroOptions = dataSeries ? dataSeries.filter(d=>d.type==="hydro").map(s=>s.files.map(f=><option key={f.id} value={f.id}>{f.name} {f.is_default ? '(Default)' : ''}</option>)) : null;
    const fuelOptions = dataSeries ? dataSeries.filter(d=>d.type==="fuel").map(s=>s.files.map(f=><option key={f.id} value={f.id}>{f.name} {f.is_default ? '(Default)' : ''}</option>)) : null;
    const interconnectionOptions = dataSeries ? dataSeries.filter(d=>d.type==="interconnection").map(s=>s.files.map(f=><option key={f.id} value={f.id}>{f.name} {f.is_default ? '(Default)' : ''}</option>)) : null;
    const maintenanceOptions = dataSeries ? dataSeries.filter(d=>d.type==="maintenance").map(s=>s.files.map(f=><option key={f.id} value={f.id}>{f.name} {f.is_default ? '(Default)' : ''}</option>)) : null;
    const renewableOptions = dataSeries ? dataSeries.filter(d=>d.type==="renewable").map(s=>s.files.map(f=><option key={f.id} value={f.id}>{f.name} {f.is_default ? '(Default)' : ''}</option>)) : null;
    const [tags, setTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState(investment.tag);

    const handleCloseFirmsModal = () => setFirmsModalShow(false);

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/simulation/tags'))
            .then(res => res.json()).then(data => {
            let tagsArray = [];
            data.list.forEach(t=>{
                if(t.tag !== null) {
                    tagsArray.push({
                        label: t.tag,
                        value: t.tag
                    });
                }
            });
            setTags(tagsArray);
        });
    }, []);

    const updateUnitsFirmsByMarketStructure = (marketStructureId) => {
        trackPromise(
            fetch('/m40alasocho/market/structure/' + marketStructureId))
            .then(res => res.json()).then(data => {
            const firmsData = buildFirmsData(data["companies"]);
            const units = buildUnits(data["units"]);
            setInvestmentConfig(simulationConfig => {
                return {
                    ...investmentConfig,
                    marketStructureId: marketStructureId,
                    firms: firmsData,
                    units: units
                };
            });
        });
    };

    useEffect(() => {
        setStatus(investment.status);
    }, [investment.status]);


    useEffect(() => {
        if(investment.id !== '') {
            setInvestmentConfig({
                id: investment.id,
                name: investment.name,
                tag: investment.tag,
                simulationType: "energeia-investment",
                companyCode: investment.companyCode,
                projectType: investment.configuration.projectType,
                timePeriod: {...investment.configuration.timePeriod},
                marketStructureId: investment.market_structure.id,
                demand:{...investment.configuration.demand},
                hydrogen:{...investment.configuration.hydrogen},
                firms: {...investment.configuration.firms},
                units: buildUnits([...investment.configuration.units]),
                hydro: {...investment.configuration.hydro},
                renewable: {...investment.configuration.renewable},
                fuel:{...investment.configuration.fuel},
                interconnectionFixPrice: investment.configuration.interconnectionFixPrice,
                interconnectionId: investment.configuration.interconnectionId,
                maintenanceId: investment.configuration.maintenanceId,
                priceId: undefined,
                technologyGenerationId: undefined,
                strategy: investment.configuration.strategy,
                competitive: investment.configuration.competitive,
                storage: investment.configuration.storage,
                resConnected: investment.configuration.resConnected,
                h2Connected: investment.configuration.h2Connected,
                temporalCorrelation: investment.configuration.temporalCorrelation ? investment.configuration.temporalCorrelation : "none",
                plants: [...investment.configuration.plants]
            });
        }else{
            setInvestmentConfig(defaultSimulationConfig);
            updateUnitsFirmsByMarketStructure(defaultMarketStructureId);
        }
        // eslint-disable-next-line
    }, [investment, defaultSimulationConfig, defaultMarketStructureId]);


    async function startSimulation () {
        updateInvestment({...investment,
            "status": "RUNNING"})
        setStatus("RUNNING");
        sendNotification('The simulation has started. You will be informed when finished', "success");
        const response = await fetch('/m40alasocho/simulation/' + investmentConfig.id + '/run?type=energeia-investment', {
            method: 'POST' ,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const json = await response.json();
        if("error" in json){
            sendNotification(json.error, "danger");
            updateInvestment({...investment,
                "status": "ERROR"})
            setStatus("ERROR");
        }
        else if("warning" in json){
            sendNotification(json.warning, "warning");
            updateInvestment({...investment,
                "status": "NEW",
                "warnings": {
                    ...investment.warnings,
                    "messages": {...JSON.parse(json.messages)}
                }
            })
            setStatus("NEW");
        }
        else {
            sendNotification('Simulation ' + json.simulation.name + ' Finished', "success");
            finishSimulation(json.list, json.simulation);
        }
    }

    const cloneSimulation = () => {
        setShowSaveAs(false);
        trackPromise(
            fetch('/m40alasocho/simulation/' + investmentConfig.id + '/clone/' + userId, {
                method: 'POST' ,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: saveAsName
                })
            }))
            .then(res => res.json()).then(data => {
            if("error" in data){
                sendNotification(data.error, "danger");
            }else {
                updateInvestmentList(data.list);
                loadInvestment(data.investment.id);
                sendNotification('Simulation cloned', "success");
            }
        });
    }

    const validateAndSave = () => {
        let validForm = true;
        let validDates = isValidDatesRange();
        setValidateForm(true);
        if(investmentConfig.name === '' || !investmentConfig.companyCode || investmentConfig.companyCode === ''){
            validForm = false;
        }
        if(!validDates){
            sendNotification("Date From can not be after Date To", "warning");
        }
        let validUnits = investmentConfig.units.filter(u=>u.updated && u.invalid).length === 0;
        if(!validForm){
            sendNotification("Please check missing fields", "warning");
        }
        if(!validUnits){
            sendNotification("Please check wrong units dates On/Off", "warning");
        }

        if(validUnits && validForm && validDates){
            trackPromise(
                fetch('/m40alasocho/simulation/user/' + userId + '/settings', {
                    method: 'POST' ,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({...investmentConfig,
                        units: investmentConfig.units.filter(u=>u.updated)
                    })
                }))
                .then(res => res.json()).then(data => {
                if("error" in data){
                    sendNotification(data.error, "danger");
                }else{
                    updateInvestmentList(data.list);
                    updateInvestment(data.simulation);
                    navigate('/investments/' + data.simulation.id);
                    sendNotification('Simulation Settings Saved', "success");
                }
            });
        }
    };

    const isValidDatesRange = () => {
        return investmentConfig.timePeriod.yearFrom < investmentConfig.timePeriod.yearTo;
    }

    const createDefaultPlant = (index) => {
        return {
            name: `Plant${index}`,
            index: index,
            type: "Wind",
            capacity: 30,
            omCost: 5,
            regFixed: 0,
            premiumContract: "premium",
            feedPremium: true,
            feedCapacity: 0,
            contractForDifferences: false,
            cfdFloor: 0,
            cfdExposure: 0,
            cfdStrike: 0,
            cfdReference: 0,
            cfdEndDate: undefined,
            transferPrice: 30
        }
    }

    const addPlant = () => {
        setInvestmentConfig({
            ...investmentConfig,
            plants: [...investmentConfig.plants, createDefaultPlant(investmentConfig.plants.length + 1)]
        });
    }

    const updatePlant = (plant) => {
        setInvestmentConfig({
            ...investmentConfig,
            plants: [...investmentConfig.plants.map(p => {
                if(p.index === plant.index){
                    return {...plant}
                }
                return p;
            })]
        });
    }

    const removePlant = (index) => {
        setInvestmentConfig({
            ...investmentConfig,
            plants: [
                ...investmentConfig.plants
                    .filter(p => p.index !== index)
                    .map((p, idx) => {
                    return {...p,
                            index: idx + 1}
            })]
        });
    }

    let selectOpts = {};
    if(selectedTag){
        selectOpts = {
            value: {
                value: selectedTag, label: selectedTag
            }
        }
    }


    let timePeriodByOption =
            <Row>
                <Col sm={2}>
                    <Form.Label>From
                        <Form.Control type="number" min={2000} placeholder="Year" size="sm" isInvalid={validateForm && investmentConfig.timePeriod.yearFrom === ''}  value={investmentConfig.timePeriod.yearFrom} onChange={event=>{
                            setInvestmentConfig({...investmentConfig, timePeriod: {
                                    ...investmentConfig.timePeriod,
                                    yearFrom: parseInt(event.target.value)
                                }});
                        }}/>
                    </Form.Label>
                </Col>
                <Col sm={2}>
                    <Form.Label>To
                        <Form.Control type="number" min={2000} placeholder="Year" size="sm" isInvalid={validateForm && investmentConfig.timePeriod.yearTo === ''}  value={investmentConfig.timePeriod.yearTo} onChange={event=>{
                            setInvestmentConfig({...investmentConfig, timePeriod: {
                                    ...investmentConfig.timePeriod,
                                    yearTo: parseInt(event.target.value)
                                }});
                        }}/>
                    </Form.Label>
                </Col>
            </Row>

    let demandElasticOptions = null;
    if(investmentConfig.demand.type === "elastic"){
        demandElasticOptions = (
            <Row>
                <Col sm={6}>
                    <Form.Label className={"text-truncate"} title={"% elastic"}>% elastic
                        <Form.Control type="number" placeholder="% elastic" isInvalid={validateForm && investmentConfig.demand.inelasticPercentage === ''}
                                      min={0} max={100}
                                      size="sm" value={investmentConfig.demand.inelasticPercentage} onChange={event=>{
                            if(event.target.value >= 0 && event.target.value <= 100) {
                                setInvestmentConfig({
                                    ...investmentConfig, demand: {
                                        ...investmentConfig.demand,
                                        inelasticPercentage: event.target.value
                                    }
                                });
                            }
                        }}/>
                    </Form.Label>
                </Col>
            </Row>
        );
    }else{
        demandElasticOptions = null;
    }

    const firmsContract = investmentConfig.firms ? (
        <Table bordered hover size="sm">
            <thead>
            <tr>
                <th>Contracts</th>
                <th>Contract Price (€/MWh)</th>
                <th>Contract Quantity (MWh)</th>
                <th>Strategic Behaviour</th>
            </tr>
            </thead>
            <tbody>
            {Object.keys(investmentConfig.firms).map(k=>{
                return <tr key={k}>
                    <td>{k}</td>
                    <td>
                        <Form.Control type="number" min={0} style={{width:"50%"}} placeholder="Base Price (€/MWh)" size="sm" value={investmentConfig.firms[k].price} onChange={event=>{
                            if(event.target.value >= 0) {
                                setInvestmentConfig({
                                    ...investmentConfig, firms: {
                                        ...investmentConfig.firms,
                                        [k]: {
                                            ...investmentConfig.firms[k],
                                            price: event.target.value
                                        }
                                    }
                                });
                            }
                        }}/>
                    </td>
                    <td>
                        <Form.Control type="number" min={0} style={{width:"50%"}} placeholder="Base Quantity (MWh)" size="sm" value={investmentConfig.firms[k].quantity} onChange={event=>{
                            if(event.target.value >= 0) {
                                setInvestmentConfig({
                                    ...investmentConfig, firms: {
                                        ...investmentConfig.firms,
                                        [k]: {
                                            ...investmentConfig.firms[k],
                                            quantity: event.target.value
                                        }
                                    }
                                });
                            }
                        }}/>
                    </td>
                    <td>
                        <Form.Check className="float-right" type="checkbox" size="sm" checked={investmentConfig.firms[k].strategy} onChange={event=>{
                            setInvestmentConfig({...investmentConfig, firms: {
                                    ...investmentConfig.firms,
                                    [k]: {
                                        ...investmentConfig.firms[k],
                                        strategy: event.target.checked
                                    }}});
                        }}/>
                    </td>
                </tr>
            })}
            </tbody>
        </Table>
    ) : null;

    const unitsOnOff = investmentConfig.units ? (
        <div style={{width: "100%", height: "300px", overflow: "auto"}}>
            <Table bordered hover size="sm">
                <thead>
                <tr>
                    <th>Unit</th>
                    <th>Technology</th>
                    <th>Firm</th>
                    <th>On</th>
                    <th>Off</th>
                </tr>
                </thead>
                <tbody>
                {investmentConfig.units.map((u, i)=>{
                    return <tr key={i}>
                        <td>{u.code}</td>
                        <td>{u.technology}</td>
                        <td>{u.company}</td>
                        <td>
                            <Form.Control type="date" size="sm" value={u.dateOn} isInvalid={u.updated && validateForm && u.invalid} onChange={event=>{
                                const units = [...investmentConfig.units];
                                units[u.key-1] = {
                                    ...units[u.key-1],
                                    dateOn: event.target.value,
                                    updated: true,
                                    invalid: invalidUnitDates(event.target.value, u.dateOff)
                                };
                                setInvestmentConfig({...investmentConfig, units: [
                                        ...units
                                    ]
                                });
                            }}/>
                        </td>
                        <td>
                            <Form.Control type="date" size="sm" value={u.dateOff} isInvalid={u.updated && validateForm && u.invalid} onChange={event=>{
                                const units = [...investmentConfig.units];
                                units[u.key-1] = {
                                    ...units[u.key-1],
                                    dateOff: event.target.value,
                                    updated: true,
                                    invalid: invalidUnitDates(u.dateOn, event.target.value)
                                };
                                setInvestmentConfig({...investmentConfig, units: [
                                        ...units
                                    ]
                                });
                            }}/>
                        </td>
                    </tr>
                })}
                </tbody>
            </Table>
        </div>
    ) : null;

    const warningsMessages = (investment && investment.warnings) ? investment.warnings.messages : null;

    const coalFixPrice = investmentConfig.fuel.coalId === 0 ? (
        <Form.Label>Coal
            <Form.Control type="number" placeholder="Coal" size="sm" isInvalid={validateForm && investmentConfig.fuel.coalFixPrice === ''}
                          min={0} max={100}
                          value={investmentConfig.fuel.coalFixPrice} onChange={event=>{
                if(event.target.value >= 0 && event.target.value <= 100) {
                    setInvestmentConfig({
                        ...investmentConfig, fuel: {
                            ...investmentConfig.fuel,
                            coalFixPrice: event.target.value
                        }
                    });
                }
            }}/>
        </Form.Label>
    ) : null;

    const co2FixPrice = investmentConfig.fuel.co2Id === 0 ? (
        <Form.Label>CO2
            <Form.Control type="number" placeholder="CO2" size="sm" isInvalid={validateForm && investmentConfig.fuel.co2FixPrice === ''}
                          min={0} max={100}
                          value={investmentConfig.fuel.co2FixPrice} onChange={event=>{
                if(event.target.value >= 0 && event.target.value <= 100) {
                    setInvestmentConfig({
                        ...investmentConfig, fuel: {
                            ...investmentConfig.fuel,
                            co2FixPrice: parseInt(event.target.value)
                        }
                    });
                }
            }}/>
        </Form.Label>
    ) : null;

    const hydrogenFixPrice = investmentConfig.fuel.hydrogenId === 0 ? (
        <Form.Label>Hydrogen
            <Form.Control type="number" placeholder="Hydrogen" size="sm" isInvalid={validateForm && investmentConfig.fuel.hydrogenFixPrice === ''}
                          min={0} max={100}
                          value={investmentConfig.fuel.hydrogenFixPrice} onChange={event=>{
                if(event.target.value >= 0 && event.target.value <= 100) {
                    setInvestmentConfig({
                        ...investmentConfig, fuel: {
                            ...investmentConfig.fuel,
                            hydrogenFixPrice: parseInt(event.target.value)
                        }
                    });
                }
            }}/>
        </Form.Label>
    ) : null;

    const gasFixPrice = investmentConfig.fuel.gasId === 0 ? (
        <Form.Label>Gas
            <Form.Control type="number" placeholder="Gas" size="sm" isInvalid={validateForm && investmentConfig.fuel.gasFixPrice === ''}
                          min={0} max={100}
                          value={investmentConfig.fuel.gasFixPrice} onChange={event=>{
                if(event.target.value >= 0 && event.target.value <= 100) {
                    setInvestmentConfig({
                        ...investmentConfig, fuel: {
                            ...investmentConfig.fuel,
                            gasFixPrice: event.target.value
                        }
                    });
                }
            }}/>
        </Form.Label>
    ) : null;

    const taxGenerationFixPrice = investmentConfig.fuel.taxGenerationId === 0 ? (
        <Form.Label>Tax Generation
            <Form.Control type="number" placeholder="Tax Generation" size="sm" isInvalid={validateForm && investmentConfig.fuel.taxGenerationFixPrice === ''}
                          min={0} max={100}
                          value={investmentConfig.fuel.taxGenerationFixPrice} onChange={event=>{
                if(event.target.value >= 0 && event.target.value <= 100) {
                    setInvestmentConfig({
                        ...investmentConfig, fuel: {
                            ...investmentConfig.fuel,
                            taxGenerationFixPrice: event.target.value
                        }
                    });
                }
            }}/>
        </Form.Label>
    ) : null;

    const interconnectionFixPrice = investmentConfig.interconnectionId === 0 ? (
        <Form.Label>Interconnection
            <Form.Control type="number" min={0} max={100} placeholder="Interconnection" size="sm"
                          isInvalid={validateForm && investmentConfig.interconnectionFixPrice === ''} value={investmentConfig.interconnectionFixPrice} onChange={event=>{
                if(event.target.value >= 0 && event.target.value <= 100) {
                    setInvestmentConfig({
                        ...investmentConfig,
                        "interconnectionFixPrice": event.target.value
                    });
                }
            }}/>
        </Form.Label>
    ) : null;

    const hydroDispatchFix = investmentConfig.hydro.dispatch_id === 0 ? (
        <Form.Label>Hydro Dispatch
            <Form.Control type="number" min={0} placeholder="Hydro Dispatch" isInvalid={validateForm && investmentConfig.hydro.dispatchFixPrice === ''} size="sm" value={investmentConfig.hydro.dispatchFixPrice} onChange={event=>{
                setInvestmentConfig({...investmentConfig, hydro: {
                        ...investmentConfig.hydro,
                        dispatchFixPrice: event.target.value
                    }});
            }}/>
        </Form.Label>
    ) : null;

    const hydroFlowFix = investmentConfig.hydro.flow_id === 0 ? (
        <Form.Label>Hydro Flow
            <Form.Control type="number" placeholder="Hydro Flow" isInvalid={validateForm && investmentConfig.hydro.flowFixPrice === ''} size="sm" value={investmentConfig.hydro.flowFixPrice} onChange={event=>{
                setInvestmentConfig({...investmentConfig, hydro: {
                        ...investmentConfig.hydro,
                        flowFixPrice: event.target.value
                    }});
            }}/>
        </Form.Label>
    ) : null;

    const renewableWindFix = investmentConfig.renewable.wind === 0 ? (
        <Form.Label>Wind
            <Form.Control type="number" placeholder="Wind" isInvalid={validateForm && investmentConfig.renewable.windFixPrice === ''} size="sm" value={investmentConfig.renewable.windFixPrice} onChange={event=>{
                setInvestmentConfig({...investmentConfig, renewable: {
                        ...investmentConfig.renewable,
                        windFixPrice: event.target.value
                    }});
            }}/>
        </Form.Label>
    ) : null;

    const hydroFlow = investmentConfig.hydro.peak === 'monthly' || investmentConfig.hydro.peak === 'yearly' ? (
        <Form.Label>Max Flow (MWh)
            <Form.Control type="number" placeholder="Max Flow" isInvalid={validateForm && investmentConfig.hydro.maxFlow === ''}
                          min={0} size="sm" value={investmentConfig.hydro.maxFlow}
                          onChange={event=>{
                              if(event.target.value >= 0) {
                                  setInvestmentConfig({
                                      ...investmentConfig, hydro: {
                                          ...investmentConfig.hydro,
                                          maxFlow: event.target.value
                                      }
                                  });
                              }
                          }}/>
        </Form.Label>
    ) : null;

    const renewableSolarFix = investmentConfig.renewable.solar === 0 ? (
        <Form.Label>Solar
            <Form.Control type="number" placeholder="Solar" isInvalid={validateForm && investmentConfig.renewable.solarFixPrice === ''} size="sm" value={investmentConfig.renewable.solarFixPrice} onChange={event=>{
                setInvestmentConfig({...investmentConfig, renewable: {
                        ...investmentConfig.renewable,
                        solarFixPrice: event.target.value
                    }});
            }}/>
        </Form.Label>
    ) : null;

    const renewableOthersFix = investmentConfig.renewable.others === 0 ? (
        <Form.Label>Others
            <Form.Control type="number" placeholder="Others" isInvalid={validateForm && investmentConfig.renewable.othersFixPrice === ''} size="sm" value={investmentConfig.renewable.othersFixPrice} onChange={event=>{
                setInvestmentConfig({...investmentConfig, renewable: {
                        ...investmentConfig.renewable,
                        othersFixPrice: event.target.value
                    }});
            }}/>
        </Form.Label>
    ) : null;

    const renewableCogFix = investmentConfig.renewable.cog === 0 ? (
        <Form.Label>Cogeneration
            <Form.Control type="number" placeholder="Cogeneration" isInvalid={validateForm && investmentConfig.renewable.cogFixPrice === ''} size="sm" value={investmentConfig.renewable.cogFixPrice} onChange={event=>{
                setInvestmentConfig({...investmentConfig, renewable: {
                        ...investmentConfig.renewable,
                        cogFixPrice: event.target.value
                    }});
            }}/>
        </Form.Label>
    ) : null;

    const renewableSolarThFix = investmentConfig.renewable.solarTh === 0 ? (
        <Form.Label>Solar Thermal
            <Form.Control type="number" placeholder="Solar Thermal" isInvalid={validateForm && investmentConfig.renewable.solarThFixPrice === ''} size="sm" value={investmentConfig.renewable.solarThFixPrice} onChange={event=>{
                setInvestmentConfig({...investmentConfig, renewable: {
                        ...investmentConfig.renewable,
                        solarThFixPrice: event.target.value
                    }});
            }}/>
        </Form.Label>
    ) : null;

    const simulationDate = investment.id !== "" ? ((status === 'NEW' || status === 'RUNNING' || status === 'ERROR') ? (<Col sm={3}>
            <Row>
                <Col sm={12}>
                    <b>Creation Date: </b>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    {investment.creation_date.join(" ")}
                </Col>
            </Row>
        </Col>) :
        (<Col sm={3}>
            <Row>
                <Col sm={12}>
                    <b>Simulation Date: </b>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    {investment.simulation_date.join(" ")}
                </Col>
            </Row>
        </Col>)) : null;


    let buttonsBySimulation = null;
    if(investment.status === 'RUNNING' || status === 'RUNNING'){
        const simDate = new Date(investment.creation_date[0]);
        const diffDays = Math.floor((new Date() - simDate) / 86400000); // days
        buttonsBySimulation = diffDays < 1 ? (
                <div>
                    <button onClick={()=>{
                        sendNotification("The simulation is still running. You will be notified when it finishes", "warning");
                    }} style={{marginLeft:"5px"}} className="btn btn-sm btn-warning float-right">
                        Running...
                    </button>
                    <button type="button" className="btn btn-sm btn-outline-primary float-right"
                            style={{marginLeft:"5px"}}  id={investment.id}
                            onClick={() => setShowLogs(!showLogs)}
                    >
                        Progress
                    </button>
                </div>)
            :
            (
                (
                    <div>
                        <button onClick={()=>{
                            sendNotification("The simulation is still running. You will be notified when it finishes", "warning");
                        }} style={{marginLeft:"5px"}} className="btn btn-sm btn-warning float-right">
                            Running...
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-primary float-right"
                                style={{marginLeft:"5px"}}  id={investment.id}
                                onClick={() => setShowLogs(!showLogs)}
                        >
                            Progress
                        </button>
                        <button style={{marginLeft:"5px"}}
                                id={investment.id}
                                onClick={deleteSimulationModal}
                                className="btn btn-sm btn-outline-danger float-right">
                            Delete
                        </button>
                    </div>)
            )
    }else if(investment.status === 'NEW'){
        buttonsBySimulation = <div>
            <button type="button" className="btn btn-sm btn-outline-danger float-right" style={{marginLeft:"5px"}} onClick={deleteSimulationModal}  id={investment.id}>Delete</button>
            <button onClick={cancelSimulationChanges} style={{marginLeft:"5px"}} className="btn btn-sm btn-outline-secondary float-right">
                Cancel
            </button>
            <button onClick={startSimulation} style={{marginLeft:"5px"}} className="btn btn-sm btn-outline-success float-right">
                Simulate
            </button>
            <button onClick={validateAndSave} style={{marginLeft:"5px"}} className="btn btn-sm btn-outline-primary float-right">
                Save
            </button>
            <button onClick={() => {
                setSaveAsName(investmentConfig.name + "Clone");
                setShowSaveAs(true);
            }} className="btn btn-sm btn-outline-primary float-right"
                    style={{marginLeft:"5px"}} >
                Save as
            </button>
            {investment.warnings && <button onClick={() => {
                setShowWarnings(true);
            }} className="btn btn-sm btn-outline-warning float-right">
                Warnings
            </button>}
        </div>
    }else if(investment.status === 'ERROR' || status === 'ERROR'){
        buttonsBySimulation = <div>
            <button style={{marginLeft:"5px"}}
                    id={investment.id}
                    onClick={deleteSimulationModal}
                    className="btn btn-sm btn-outline-danger float-right">
                Delete
            </button>
            <button type="button" className="btn btn-sm btn-outline-primary float-right"
                    style={{marginLeft:"5px"}}  id={investment.id}
                    onClick={() => setShowLogs(!showLogs)}
            >
                Progress
            </button>
        </div>
    }

    const simulationLegend = investment.id !== "" ? (<Alert variant="primary" style={{marginTop:"10px", width: "100%"}}>
        <Row>
            <Col sm={8}>
                <Row>
                    <Col sm={5}>
                        <Row>
                            <Col sm={12}>
                                <b>Market Structure:</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                {investment.market_structure.filename}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={4}>
                        <Row>
                            <Col sm={12}>
                                <b>Name: </b>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                {investment.name}
                            </Col>
                        </Row>
                    </Col>
                    {simulationDate}
                </Row>
            </Col>
            {!readOnly && <Col sm={4}>
                {buttonsBySimulation}
            </Col>
            }
        </Row>
    </Alert>) : <Alert variant="primary" style={{marginTop:"10px", width: "98%"}}>
        <Row>
            <Col sm={9}>
                Create Simulation
            </Col>
            <Col sm={3}>
                <button onClick={cancelSimulationChanges} style={{marginLeft:"5px"}} className="btn btn-sm btn-outline-secondary float-right">
                    Cancel
                </button>
                <button onClick={validateAndSave} className="btn btn-sm btn-outline-primary float-right">
                    Save
                </button>
            </Col>
        </Row>
    </Alert>;


    return investmentConfig ? (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            {simulationLegend}
            <Form id={"idInvestmentsForm"}>
                <HorizontalLine color="#4a92db" size={3} tittle="Info" icon={<SiMarketo/>}/>
                <Row>
                    <Col sm={3}>
                        <Form.Label>Market Structure
                            <Form.Control as="select" aria-label="Market Structure" size="sm" value={investmentConfig.marketStructureId} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, "marketStructureId": parseInt(event.target.value)});
                                updateUnitsFirmsByMarketStructure(parseInt(event.target.value));
                            }}>
                                {marketStructureOptions}
                            </Form.Control>
                        </Form.Label>
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Name
                            <Form.Control type="text" placeholder="Simulation Name" isInvalid={validateForm && investmentConfig.name === ''} size="sm" value={investmentConfig.name} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, "name":event.target.value});
                            }}/>
                        </Form.Label>
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Tag
                            <CreatableSelect isClearable
                                             name={"tag"}
                                             {...selectOpts}
                                             options={tags}
                                             placeholder={"Select" +
                                                 " or create a Tag for this simulation"}
                                             onChange={newValue => {
                                                 setSelectedTag(newValue.value);
                                                 setInvestmentConfig({...investmentConfig, "tag": newValue.value})
                                             }}
                            />
                        </Form.Label>
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Project Type
                            <Form.Control as="select" aria-label="Type" size="sm" value={investmentConfig.projectType} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, projectType: event.target.value});
                            }}>
                                <option value="Renewables">Renewables</option>
                                <option value="Hydrogen">Hydrogen</option>
                            </Form.Control>
                        </Form.Label>
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Company Code
                            <CreatableSelect isClearable
                                             options={Object.keys(investmentConfig.firms).map(k => {
                                                return {label: k, value:k}
                                                })}
                                             value={
                                                        {label: investmentConfig.companyCode, value: investmentConfig.companyCode}
                                                    }
                                             placeholder={"Search or type company Code"}
                                             onChange={newValue => {
                                                 if(newValue) {
                                                     setInvestmentConfig({
                                                             ...investmentConfig,
                                                             "companyCode": newValue.value
                                                         });
                                                 }else{
                                                     setInvestmentConfig({
                                                         ...investmentConfig,
                                                         "companyCode": ""
                                                     });
                                                 }
                                             }}
                                             isValidNewOption={newValue=> {
                                                 return newValue && newValue.length >= 2 && newValue.length <= 3;
                                             }}
                            />
                        </Form.Label>
                    </Col>
                </Row>
                <HorizontalLine color="#4a92db" size={3} tittle="Time Period" icon={<GoCalendar/>}/>
                <Row>
                    <Col sm={8}>
                        {timePeriodByOption}
                    </Col>
                </Row>
                <Row>
                    <HorizontalLine color="#4a92db" size={3} tittle="Demand" icon={<GiElectric/>}/>
                    <Col sm={3}>
                        <Form.Label>Demand Data (GWh)
                            <Form.Control as="select" aria-label="Demand" size="sm" value={investmentConfig.demand.id} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, demand: {
                                        ...investmentConfig.demand,
                                        id: event.target.value
                                    }});
                            }}>
                                {demandOptions}
                            </Form.Control>
                        </Form.Label>
                    </Col>
                    <Col>
                        <Form.Label>Demand Growth (%)
                            <Form.Control type="number" placeholder="Demand Growth (%)" isInvalid={validateForm && investmentConfig.demand.growth === ''}
                                          min={1} max={100} size="sm" value={investmentConfig.demand.growth} onChange={event=>{
                                if(event.target.value <= 100 && event.target.value >= 1) {
                                    setInvestmentConfig({
                                        ...investmentConfig, demand: {
                                            ...investmentConfig.demand,
                                            growth: event.target.value
                                        }
                                    });
                                }
                            }}/>
                        </Form.Label>
                    </Col>
                    <Col>
                        <Form.Label>Peakiness (%)
                            <Form.Control type="number" placeholder="Peakiness (%)" isInvalid={validateForm && investmentConfig.demand.peakiness === ''}
                                          min={-100} max={100} size="sm" value={investmentConfig.demand.peakiness} onChange={event=>{
                                if(event.target.value <= 100 && event.target.value >= -100) {
                                    setInvestmentConfig({
                                        ...investmentConfig, demand: {
                                            ...investmentConfig.demand,
                                            peakiness: event.target.value
                                        }
                                    });
                                }
                            }}/>
                        </Form.Label>
                    </Col>
                    <Col>
                        <Form.Label>Change (%)
                            <Form.Control type="number" placeholder="Change (%)" isInvalid={validateForm && investmentConfig.demand.variation === ''} size="sm" value={investmentConfig.demand.variation} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, demand: {
                                        ...investmentConfig.demand,
                                        variation: event.target.value
                                    }});
                            }}/>
                        </Form.Label>
                    </Col>
                    <Col>
                        <Form.Label>Max Price (€/MWh)
                            <Form.Control type="number" placeholder="Max Price" isInvalid={validateForm && investmentConfig.demand.maxPrice === ''}
                                          min={0}
                                          size="sm" value={investmentConfig.demand.maxPrice} onChange={event=>{
                                if(event.target.value >= 0) {
                                    setInvestmentConfig({
                                        ...investmentConfig, demand: {
                                            ...investmentConfig.demand,
                                            maxPrice: event.target.value
                                        }
                                    });
                                }
                            }}/>
                        </Form.Label>
                    </Col>
                    <Col sm={1}>
                        <Form.Label>Type
                            <Form.Control as="select" aria-label="Period" size="sm" value={investmentConfig.demand.type} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, demand: {
                                        ...investmentConfig.demand,
                                        type: event.target.value
                                    }});
                            }}>
                                <option value="elastic">Elastic</option>
                                <option value="inelastic">Inelastic</option>
                            </Form.Control>
                        </Form.Label>
                    </Col>
                    <Col>
                        {demandElasticOptions}
                    </Col>
                </Row>
                <HorizontalLine color="#4a92db" size={3} tittle="Firms & Units" icon={<GrTechnology/>}/>
                <Row>
                    <Col>
                        <Form.Label>Maintenance
                            <Form.Control as="select" aria-label="Maintenance" size="sm" value={investmentConfig.maintenanceId} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, "maintenanceId":event.target.value});
                            }}>
                                {maintenanceOptions}
                            </Form.Control>
                        </Form.Label>
                    </Col>
                    <Col>
                        <button type="button" onClick={(event)=>{
                            event.preventDefault();
                            setFirmsModalShow(true);
                        }} className="btn btn-outline-primary">
                            <BsTable/> Contracts
                        </button>
                    </Col>
                    <Col>
                        <button type="button" onClick={(event)=>{
                            event.preventDefault();
                            setUnitsModalShow(true);
                        }} className="btn btn-outline-primary">
                            <BsTable/> Units On/Off
                        </button>
                    </Col>
                </Row>
                <HorizontalLine color="#4a92db" size={3} tittle="Fuel Prices" icon={<FaGasPump/>}/>
                <Row>
                    <Col>
                        <Form.Label>Coal (€/MWht)
                            <Form.Control as="select" aria-label="Coal" size="sm" value={investmentConfig.fuel.coalId} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, fuel: {
                                        ...investmentConfig.fuel,
                                        coalId: parseInt(event.target.value)
                                    }});
                            }}>
                                {fuelOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {coalFixPrice}
                    </Col>
                    <Col>
                        <Form.Label>Gas (€/MWht)
                            <Form.Control as="select" aria-label="Gas" size="sm" value={investmentConfig.fuel.gasId} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, fuel: {
                                        ...investmentConfig.fuel,
                                        gasId: parseInt(event.target.value)
                                    }});
                            }}>
                                {fuelOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {gasFixPrice}
                    </Col>
                    <Col>
                        <Form.Label>CO2 (€/Ton)
                            <Form.Control as="select" aria-label="Co2" size="sm" value={investmentConfig.fuel.co2Id} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, fuel: {
                                        ...investmentConfig.fuel,
                                        co2Id: parseInt(event.target.value)
                                    }});
                            }}>
                                {fuelOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {co2FixPrice}
                    </Col>
                    <Col>
                        <Form.Label>Hydrogen
                            <Form.Control as="select" aria-label="Hydrogen" size="sm" value={investmentConfig.fuel.hydrogenId} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, fuel: {
                                        ...investmentConfig.fuel,
                                        hydrogenId: parseInt(event.target.value)
                                    }});
                            }}>
                                {fuelOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {hydrogenFixPrice}
                    </Col>
                    <Col>
                        <Form.Label>Tax Generation (%)
                            <Form.Control as="select" aria-label="Tax Generation" size="sm" value={investmentConfig.fuel.taxGenerationId} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, fuel: {
                                        ...investmentConfig.fuel,
                                        taxGenerationId: parseInt(event.target.value)
                                    }});
                            }}>
                                {fuelOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {taxGenerationFixPrice}
                    </Col>
                    <Col>
                        <Form.Label className={"text-truncate"} title={"Interconnection prices (€/MWh)"}>Interconnection prices (€/MWh)
                            <Form.Control as="select" aria-label="Interconnections" size="sm" value={investmentConfig.interconnectionId} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, "interconnectionId": parseInt(event.target.value)});
                            }}>
                                {interconnectionOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {interconnectionFixPrice}
                    </Col>
                </Row>
                <Row>
                    <HorizontalLine color="#4a92db" size={3} tittle={investmentConfig.projectType} icon={<BiHistory/>} />
                    <Col sm={3}>
                        <Row>
                            <Col sm={12}>
                                <InputGroup className="mb-3 costs-item-width">
                                    <InputGroup.Text id="basic-addon3">
                                        Electrolysis Efficiency (MWh/Kg)
                                    </InputGroup.Text>
                                    <Form.Control type="number"
                                                  className={"text-right"}
                                                  placeholder="Electrolysis Efficiency (MWh/Kg)" isInvalid={validateForm && investmentConfig.hydrogen.electrolysis === ''}
                                                  size="sm" value={investmentConfig.hydrogen.electrolysis} onChange={event=>{
                                        setInvestmentConfig({
                                            ...investmentConfig, hydrogen: {
                                                ...investmentConfig.hydrogen,
                                                electrolysis: parseInt(event.target.value)
                                            }
                                        });
                                    }}/>
                                </InputGroup>
                            </Col>
                            <Col sm={12}>
                                <InputGroup className="mb-3 costs-item-width">
                                    <InputGroup.Text id="basic-addon3">
                                        Electrolysis Power (MW)
                                    </InputGroup.Text>
                                    <Form.Control type="number"
                                                  className={"text-right"}
                                                  placeholder="Electrolysis Power (MW)" isInvalid={validateForm && investmentConfig.hydrogen.electrolysisPower === ''}
                                                  size="sm" value={investmentConfig.hydrogen.electrolysisPower} onChange={event=>{
                                        setInvestmentConfig({
                                            ...investmentConfig, hydrogen: {
                                                ...investmentConfig.hydrogen,
                                                electrolysisPower: parseInt(event.target.value)
                                            }
                                        });
                                    }}/>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={3}>
                        <Row>
                            <Col sm={12}>
                                <InputGroup className="mb-3 costs-item-width">
                                    <InputGroup.Text id="basic-addon3">
                                        Operating Cost (€/Kg)
                                    </InputGroup.Text>
                                    <Form.Control type="number"
                                                  className={"text-right"}
                                                  placeholder="Operating Cost (€/Kg)" isInvalid={validateForm && investmentConfig.hydrogen.oCost === ''}
                                                  size="sm" value={investmentConfig.hydrogen.oCost} onChange={event=>{
                                        setInvestmentConfig({
                                            ...investmentConfig, hydrogen: {
                                                ...investmentConfig.hydrogen,
                                                oCost: parseInt(event.target.value)
                                            }
                                        });
                                    }}/>
                                </InputGroup>
                            </Col>
                            <Col sm={12}>
                                <InputGroup className="mb-3 costs-item-width">
                                    <InputGroup.Text id="basic-addon3">
                                        Water Consumption (l/Kg)
                                    </InputGroup.Text>
                                    <Form.Control type="number"
                                                  className={"text-right"}
                                                  placeholder="Water Consumption (l/Kg)" isInvalid={validateForm && investmentConfig.hydrogen.h2oConsumption === ''}
                                                  size="sm" value={investmentConfig.hydrogen.h2oConsumption} onChange={event=>{
                                        setInvestmentConfig({
                                            ...investmentConfig, hydrogen: {
                                                ...investmentConfig.hydrogen,
                                                h2oConsumption: parseInt(event.target.value)
                                            }
                                        });
                                    }}/>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={3}>
                        <Row>
                            <Col sm={12}>
                                <InputGroup className="mb-3 costs-item-width">
                                    <InputGroup.Text id="basic-addon3">
                                        Capacity Payment (€/MW)
                                    </InputGroup.Text>
                                    <Form.Control type="number"
                                                  className={"text-right"}
                                                  placeholder="Capacity Payment (€/MW)" isInvalid={validateForm && investmentConfig.hydrogen.capacity === ''}
                                                  size="sm" value={investmentConfig.hydrogen.capacity} onChange={event=>{
                                        setInvestmentConfig({
                                            ...investmentConfig, hydrogen: {
                                                ...investmentConfig.hydrogen,
                                                capacity: parseInt(event.target.value)
                                            }
                                        });
                                    }}/>
                                </InputGroup>
                            </Col>
                            <Col sm={12}>
                                <InputGroup className="mb-3 costs-item-width">
                                    <InputGroup.Text id="basic-addon3">
                                        Feed-in Premium (€/kg)
                                    </InputGroup.Text>
                                    <Form.Control type="number"
                                                  className={"text-right"}
                                                  placeholder="Feed-in Premium (€/kg)" isInvalid={validateForm && investmentConfig.hydrogen.feedInPremium === ''}
                                                  size="sm" value={investmentConfig.hydrogen.feedInPremium} onChange={event=>{
                                        setInvestmentConfig({
                                            ...investmentConfig, hydrogen: {
                                                ...investmentConfig.hydrogen,
                                                feedInPremium: parseInt(event.target.value)
                                            }
                                        });
                                    }}/>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={3}>
                        <Row>
                            <Col sm={12}>
                                <InputGroup className="mb-3 costs-item-width">
                                    <InputGroup.Text id="basic-addon3">
                                        Water Cost (€/l)
                                    </InputGroup.Text>
                                    <Form.Control type="number"
                                                  className={"text-right"}
                                                  placeholder="Water Cost (€/l)" isInvalid={validateForm && investmentConfig.hydrogen.h2o_cost === ''}
                                                  size="sm" value={investmentConfig.hydrogen.h2o_cost} onChange={event=>{
                                        setInvestmentConfig({
                                            ...investmentConfig, hydrogen: {
                                                ...investmentConfig.hydrogen,
                                                h2o_cost: parseFloat(event.target.value)
                                            }
                                        });
                                    }}/>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <HorizontalLine color="#4a92db" size={3} tittle="Hydro" icon={<BiWater/>} />
                    <Col sm={2}>
                        <Form.Label>Peak Shaving
                            <Form.Control as="select" aria-label="Hydro" size="sm" value={investmentConfig.hydro.peak} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, hydro: {
                                        ...investmentConfig.hydro,
                                        peak: event.target.value
                                    }});
                            }}>
                                <optgroup label="Yes">
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                </optgroup>
                                <optgroup label="No">
                                    <option value="real">Real</option>
                                </optgroup>
                            </Form.Control>
                        </Form.Label>
                    </Col>
                    {
                        investmentConfig.hydro.peak !== 'real' &&
                        <Col sm={2}>
                            {hydroFlow}
                        </Col>
                    }
                    <Col sm={4}>
                        <Form.Label>Dispatchable Hydro (MWh)
                            <Form.Control as="select" aria-label="Hydro" size="sm" value={investmentConfig.hydro.dispatch_id} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, hydro: {
                                        ...investmentConfig.hydro,
                                        dispatch_id: parseInt(event.target.value)
                                    }});
                            }}>
                                {hydroOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {hydroDispatchFix}
                    </Col>
                    <Col sm={4}>
                        <Form.Label>Run of River Hydro (MWh)
                            <Form.Control as="select" aria-label="Hydro" size="sm" value={investmentConfig.hydro.flow_id} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, hydro: {
                                        ...investmentConfig.hydro,
                                        flow_id: parseInt(event.target.value)
                                    }});
                            }}>
                                {hydroOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {hydroFlowFix}
                    </Col>
                </Row>
                <Row>
                    <HorizontalLine  color="#4a92db" size={3} tittle="Renewables availability" icon={<FaRecycle/>}/>
                    <Col>
                        <Form.Label>Wind (%)
                            <Form.Control as="select" aria-label="Eolic" size="sm" value={investmentConfig.renewable.wind} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, renewable: {
                                        ...investmentConfig.renewable,
                                        wind: parseInt(event.target.value)
                                    }});
                            }}>
                                {renewableOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {renewableWindFix}
                    </Col>
                    <Col>
                        <Form.Label>Solar (%)
                            <Form.Control as="select" aria-label="Solar" size="sm" value={investmentConfig.renewable.solar} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, renewable: {
                                        ...investmentConfig.renewable,
                                        solar: parseInt(event.target.value)
                                    }});
                            }}>
                                {renewableOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {renewableSolarFix}
                    </Col>
                    <Col>
                        <Form.Label>Solar Thermal (%)
                            <Form.Control as="select" aria-label="Solar Thermal" size="sm" value={investmentConfig.renewable.solarTh} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, renewable: {
                                        ...investmentConfig.renewable,
                                        solarTh: parseInt(event.target.value)
                                    }});
                            }}>
                                {renewableOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {renewableSolarThFix}
                    </Col>
                    <Col>
                        <Form.Label>Others (%)
                            <Form.Control as="select" aria-label="Others" size="sm" value={investmentConfig.renewable.others} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, renewable: {
                                        ...investmentConfig.renewable,
                                        others: parseInt(event.target.value)
                                    }});
                            }}>
                                {renewableOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {renewableOthersFix}
                    </Col>
                    <Col>
                        <Form.Label>Cogeneration (%)
                            <Form.Control as="select" aria-label="Cogeneration" size="sm" value={investmentConfig.renewable.cog} onChange={event=>{
                                setInvestmentConfig({...investmentConfig, renewable: {
                                        ...investmentConfig.renewable,
                                        cog: parseInt(event.target.value)
                                    }});
                            }}>
                                {renewableOptions}
                                <option value={0}>Manual</option>
                            </Form.Control>
                        </Form.Label>
                        {renewableCogFix}
                    </Col>
                </Row>
                <HorizontalLine color="#4a92db" size={3} tittle="Plants" icon={<GiFactory/>}/>
                <Row style={{marginBottom: "100px"}}>
                    <InvestmentPlants plants={investmentConfig.plants}
                                      projectType={investmentConfig.projectType}
                                      addPlant={addPlant}
                                      updatePlant={updatePlant}
                                      removePlant={removePlant}
                    />
                </Row>
                {
                    investmentConfig.projectType === 'Renewables' &&
                    <HorizontalLine color="#4a92db" size={3} tittle="Simulation" icon={<GrServerCluster/>}/>
                }
                <Row style={{marginBottom: "100px"}}>
                    <Col sm={2}>
                        <Form.Group className="float-left">
                            <Form.Check type="checkbox" label="Strategic" checked={investmentConfig.strategy} onChange={event=>{
                                setInvestmentConfig({...investmentConfig,
                                    strategy: event.target.checked
                                });
                            }}/>
                        </Form.Group>
                    </Col>
                    <Col sm={2}>
                        <Form.Group className="float-left">
                            <Form.Check type="checkbox" label="Storage" checked={investmentConfig.storage} onChange={event=>{
                                setInvestmentConfig({...investmentConfig,
                                    storage: event.target.checked
                                });
                            }}/>
                        </Form.Group>
                    </Col>
                    <Col sm={2} hidden={investmentConfig.projectType !== 'Hydrogen'}>
                        <Form.Group className="float-left">
                            <Form.Check type="checkbox" label="RES connected" checked={investmentConfig.resConnected} onChange={event=>{
                                setInvestmentConfig({...investmentConfig,
                                    resConnected: event.target.checked
                                });
                            }}/>
                        </Form.Group>
                    </Col>
                    <Col sm={2} hidden={investmentConfig.projectType !== 'Hydrogen'}>
                        <Form.Group className="float-left">
                            <Form.Check type="checkbox" label="H2 connected" checked={investmentConfig.h2Connected} onChange={event=>{
                                setInvestmentConfig({...investmentConfig,
                                    h2Connected: event.target.checked
                                });
                            }}/>
                        </Form.Group>
                    </Col>
                    <Col sm={2} hidden={investmentConfig.projectType !== 'Hydrogen'}>
                        <Form.Label>Temporal Correlation
                            <Form.Control as="select" aria-label="Temporal Correlation" size="sm"
                                          value={investmentConfig.temporalCorrelation}
                                          onChange={event => {
                                              setInvestmentConfig({
                                                  ...investmentConfig,
                                                  temporalCorrelation: event.target.value
                                              });
                                          }}>
                                <option value="monthly">Monthly</option>
                                <option value="hourly">Hourly</option>
                                <option value="none">None</option>
                            </Form.Control>
                        </Form.Label>
                    </Col>
                </Row>
            </Form>
            <Modal show={firmsModalShow} size="lg" onHide={handleCloseFirmsModal} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Contracts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {firmsContract}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFirmsModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={unitsModalShow} size="xl" onHide={()=>{
                setUnitsModalShow(false);
            }} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Units On/Off</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {unitsOnOff}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{
                        setUnitsModalShow(false);
                    }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showSaveAs} onHide={()=>{
                setShowSaveAs(false)
            }} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Save as</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Name
                        <Form.Control type="text" placeholder="Simulation Name" isInvalid={saveAsName === ''} size="sm" value={saveAsName} onChange={event=>{
                            setSaveAsName(event.target.value);
                        }}/>
                    </Form.Label>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{
                        setShowSaveAs(false)
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={cloneSimulation}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                showLogs &&
                <Row>
                    <Modal show={showLogs} size={"lg"} onHide={()=>{
                        setShowLogs(false)
                    }} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Simulation progress</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <SimulationProgressMessages simulationId={investment.id}
                                                        userId={username}
                                                        isStrategy={investment.configuration.strategy} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>{
                                setShowLogs(false)
                            }}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Row>
            }
            <Modal show={showWarnings} onHide={()=>{
                setShowWarnings(false)
            }} animation={false} size={"xl"}>
                <Modal.Header closeButton>
                    <Modal.Title>Warnings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SimulationWarnings messages={warningsMessages} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{
                        setShowWarnings(false)
                    }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </ErrorBoundary>
    ) : null;
}

export default InvestmentConfigForm;